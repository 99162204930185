@tailwind base;
@tailwind components;
@tailwind utilities;




@layer components{

  
    .project__title {
        grid-area: project-title;
        text-align: right;
        padding: 0 2vw 2vw 0;
        font-family: 'Italiana', serif;
        font-weight: 400;
      }
      .project__title--main {
        font-size: clamp(2rem, 10vw, 9rem);
        display: block;
        line-height: 0.9;
      }
      .project__title--sub {
        font-size: clamp(1.5rem, 4vw, 3rem);
        line-height: 1;
        margin-right: 0.5vw;
      }
      .project__img {
        position: relative;
        background-size: cover;
        background-position: 50% 50%;
        margin-bottom: 10vh;
      }
      .project__img--left {
        grid-area: project-img-left;
        grid-column-end: 3;
        border-radius: 0 30vh 30vh 0;
        height: 60vh;
      }
      .project__img--right {
        grid-area: project-img-right;
        border-radius: 40vh 0 0 40vh;
        height: 80vh;
      }
}